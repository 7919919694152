import axios from "axios";

const apiURL = process.env.REACT_APP_BACKEND_HOST;

export const addFavoriteDrugAPI = async ({ hash, code }) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${apiURL}/backend/${hash}/add-favorite-drug`,
      data: {
        drug: code,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const removeFavoriteDrugAPI = async ({ hash, code }) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${apiURL}/backend/${hash}/remove-favorite-drug`,
      data: {
        drug: code,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addSideEffectsDrugAPI = async ({ hash, code }) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${apiURL}/backend/${hash}/add-side-effects-drug`,
      data: {
        drug: code,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const removeSideEffectsDrugAPI = async ({ hash, code }) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${apiURL}/${hash}/remove-side-effects-drug`,
      data: {
        drug: code,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
