import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReportDataAPI } from "../../api/dataAPI";
import {
  setFavoriteDrugs,
  setsideEffectsDrugs,
  validateHash,
} from "./userData";

const initialState = {
  data: {},
  isLoading: true,
};

export const getReportData = createAsyncThunk(
  "reportData/getData",
  async (data, thunkAPI) => {
    try {
      const response = await getReportDataAPI(data);
      thunkAPI.dispatch(validateHash(true));
      thunkAPI.dispatch(setFavoriteDrugs(response.user.favoriteDrugs));
      thunkAPI.dispatch(setsideEffectsDrugs(response.user.sideEffectsDrugs));
      return response.payload;
    } catch (error) {
      thunkAPI.dispatch(validateHash(false));
      return thunkAPI.rejectWithValue();
    }
  }
);

const reportData = createSlice({
  name: "reportData",
  initialState,
  reducers: {},
  extraReducers: {
    [getReportData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    [getReportData.pending]: (state) => {
      state.isLoading = true;
    },
    [getReportData.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

// export const {} = reportData.actions;

export default reportData.reducer;
