import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  Group,
  Paper,
  ScrollArea,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
  Title,
} from "@mantine/core";

import { Category, SingleDrug } from "../components";

import { RiErrorWarningFill } from "react-icons/ri";
import { FaStar } from "react-icons/fa";

import appStrings from "../assets/json/appStrings.json";
import drugStrings from "../assets/json/drugStrings.json";

const Search = () => {
  const { data } = useSelector((state) => state.reportData);
  const { lang } = useSelector((state) => state.userData);

  const localStrings = appStrings[lang];

  const [searchValue, setSearchValue] = useState("");

  return (
    <Category>
      <Title order={2} my="lg">
        {localStrings.navigation.search}
      </Title>
      <TextInput
        mb="lg"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder={localStrings.searchBox}
        radius="md"
        size="md"
      />
      <SimpleGrid cols={2} breakpoints={[{ maxWidth: "xs", cols: 1 }]}>
        {data.drug_index &&
          Object.keys(localStrings.categories).map((category, index) => (
            <div key={index}>
              <Text weight="bold">{localStrings.categories[category]}</Text>
              <ScrollArea
                style={{ height: 400 }}
                sx={(theme) => ({
                  backgroundColor: theme.colors.gray[2],
                  padding: theme.spacing.lg,
                })}
              >
                <Stack spacing="sm">
                  {["red", "yellow", "green"].map(
                    (color) =>
                      data?.drug_index?.[category]?.[color].length > 0 && (
                        <Paper
                          key={color}
                          sx={(theme) => ({
                            backgroundColor: "transparent",
                            padding: 0,
                            display: "flex",
                            gap: theme.spacing.sm,
                            flexWrap: "wrap",
                          })}
                        >
                          {data?.drug_index?.[category]?.[color]?.map(
                            (drug, index) =>
                              searchValue !== "" &&
                              drugStrings?.[drug]?.[lang]
                                .toLowerCase()
                                .includes(searchValue.toLocaleLowerCase()) && (
                                <SingleDrug
                                  key={index}
                                  name={drugStrings[drug]?.[lang] || drug}
                                  color={color}
                                  isFavoritePage
                                  category={category}
                                  code={drug}
                                />
                              )
                          )}
                        </Paper>
                      )
                  )}
                </Stack>
              </ScrollArea>
            </div>
          ))}
      </SimpleGrid>
      <Group mt="xl">
        <ThemeIcon variant="outline" color="dark">
          <RiErrorWarningFill />
        </ThemeIcon>
        <Text>{localStrings.actions.sideEffects}</Text>
      </Group>
      <Group mb="xl" mt="xs">
        <ThemeIcon variant="outline" color="dark">
          <FaStar />
        </ThemeIcon>
        <Text>{localStrings.actions.favorite}</Text>
      </Group>
      <Text size="sm">
        <strong>{localStrings.warnings.general[0]}</strong>
        {localStrings.warnings.general[1]}
      </Text>
    </Category>
  );
};

export default Search;
