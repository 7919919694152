import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Container,
  Divider,
  Group,
  Image,
  List,
  ListItem,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";

import {
  NeurologyIcon,
  PsychiatryIcon,
  risk_effects,
  risk_efficacy,
  risk_metabolism,
  evidence_1A,
  guidelines,
  labels,
  levels,
} from "../../assets/img";

import strings from "../../assets/json/staticPages.json";
import ReactMarkdown from "react-markdown";

const Help = () => {
  const { lang } = useSelector((state) => state.userData);

  const theme = useMantineTheme();

  const localStrings = strings[lang].help;
  return (
    <>
      <Container>
        <Title my="xl" order={2}>
          {localStrings.title}
        </Title>
        <Text>{localStrings.section1.p}</Text>
        <List my="md">
          {localStrings.section1.list.map((item, index) => (
            <ListItem key={index}>{item}</ListItem>
          ))}
        </List>

        <Divider my="xl" />

        <Text>{localStrings.section2.p1}</Text>
        <Group position="center" spacing="xl" my="md">
          <Group direction="column" align="center">
            <PsychiatryIcon width="100px" fill={theme.colors.dark[4]} />
            <Text>{localStrings.section2.psy}</Text>
          </Group>
          <Group direction="column" align="center">
            <NeurologyIcon width="100px" fill={theme.colors.dark[4]} />
            <Text>{localStrings.section2.neuro}</Text>
          </Group>
        </Group>
        <Text>{localStrings.section2.p2}</Text>

        <Stack spacing={0} sx={{ maxWidth: "650px", margin: "0 auto" }}>
          {Object.keys(localStrings.section2.list).map((color, index) => (
            <Group noWrap key={index}>
              <Box
                sx={{
                  backgroundColor: theme.colors[color][5],
                  borderRadius: theme.radius.sm,
                  minWidth: '90px',
                  textAlign: 'center'
                }}
                p="xs"
              >
                {localStrings.section2.list[color].title}
              </Box>
              <ReactMarkdown>
                {localStrings.section2.list[color].content}
              </ReactMarkdown>
            </Group>
          ))}
        </Stack>

        <Text>{localStrings.section2.p3}</Text>

        <Divider my="xl" />

        <Text>{localStrings.section3.p1}</Text>
        <Text my="md">{localStrings.section3.p2}</Text>

        <Group
          noWrap
          position="apart"
          style={{ maxWidth: "650px", margin: "1.5em auto" }}
        >
          <Group direction="column" align="center">
            <Image src={risk_metabolism} width={80} />
            <Text align="center">{localStrings.section3.icons.metabolism}</Text>
          </Group>
          <Group direction="column" align="center">
            <Image src={risk_efficacy} width={80} />
            <Text align="center">{localStrings.section3.icons.efficacy}</Text>
          </Group>
          <Group direction="column" align="center">
            <Image src={risk_effects} width={80} />
            <Text align="center">{localStrings.section3.icons.effects}</Text>
          </Group>
        </Group>

        <Text my="md" weight="bold">
          {localStrings.section3.p3}
        </Text>

        <Divider my="xl" />

        <Text>{localStrings.section4.p1}</Text>
        <Text my="md">{localStrings.section4.p2}</Text>

        <Stack
          align="flex-start"
          sx={{ maxWidth: "650px", margin: "1.5em auto" }}
        >
          <Group noWrap>
            <Image src={evidence_1A} width={80} />
            <div>
              <Text>{localStrings.section4.icons.evidence.primary}</Text>
              <Text size="xs">
                {localStrings.section4.icons.evidence.secondary}
              </Text>
            </div>
          </Group>
          <Group noWrap>
            <Image src={guidelines} width={80} />
            <Text>{localStrings.section4.icons.guidelines}</Text>
          </Group>
          <Group noWrap>
            <Image src={labels} width={80} />
            <Text>{localStrings.section4.icons.label}</Text>
          </Group>
        </Stack>

        <Divider my="xl" />

        <Text>{localStrings.section5.p1}</Text>
        <Text my="md">{localStrings.section5.p2}</Text>

        <Image src={levels} sx={{ maxWidth: 500, margin: "auto" }} />

        <Text align="center" size="sm">
          <ReactMarkdown>{localStrings.section5.label}</ReactMarkdown>
        </Text>

        {localStrings.section5.levels.map((level, index) => (
          <Group key={index} direction="column" mt="lg" spacing="xs">
            <Text weight="bold" size="lg">
              {level.title}
            </Text>
            <Text>{level.content}</Text>
          </Group>
        ))}
      </Container>
    </>
  );
};

export default Help;
