import { useSelector } from "react-redux";

export const useDrugInfo = (code) => {
  const { favoriteDrugs, sideEffectsDrugs } = useSelector(
    (state) => state.userData
  );

  const isFavorite = favoriteDrugs.includes(code);
  const hasSideEffect = sideEffectsDrugs.includes(code);

  return [isFavorite, hasSideEffect];
};
