import { Center, Container, Loader } from "@mantine/core";
import React from "react";
import { useGetData } from "../hooks/useGetData";

const Category = ({ children }) => {
  const [isLoading, hashIsValid] = useGetData();

  if (isLoading) {
    return (
      <Center>
        <Loader mt="xl" size="lg" variant="dots" />
      </Center>
    );
  } else {
    if (!hashIsValid) {
      return <>Invalid hash</>;
    } else {
      return <Container px={0}>{children}</Container>;
    }
  }
};

export default Category;
