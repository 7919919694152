const company = localStorage.getItem("company");

let color, headerColor, logo;

switch (company) {
  case "hg":
    color = "green";
    headerColor = "#37B24D";
    logo = 'hg';
    break;
  case "unilabs":
    color = "orange";
    headerColor = "#FF922B";
    logo = 'unilabs';
    break;
	case "nimgenetics":
    color = "green";
    headerColor = "#71CC98";
    logo = 'nimgenetics';
    break;

  default:
    color = "green";
    headerColor = "#37B24D";
    logo = 'hg';
    break;
}

const theme = {
  // Colors
  colors: {},
  primaryColor: color,
  primaryShade: 4,

  other: {
    headerColor: headerColor,
    logo: logo,
  },

  // Typography
  fontFamily: "Poppins, sans-serif",
  headings: {
    fontFamily: "Barlow Condensed, sans-serif",
  },
};

export default theme;
