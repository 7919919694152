import React from "react";
import { Group, Image, Table, Text } from "@mantine/core";

import {
  evidence_1A,
  evidence_1B,
  evidence_2A,
  evidence_2B,
  evidence_3,
  evidence_4,
  evidence_NA,
  guidelines_NA,
  guidelines,
  labels_NA,
  labels,
} from "../assets/img";

import appStrings from "../assets/json/appStrings.json";
import { Link, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";

const GenesTable = ({ data }) => {
  const { lang } = useSelector((state) => state.userData);

  const localStrings = appStrings[lang];

  const { url } = useRouteMatch();

  const evidenceIcons = {
    "1A": evidence_1A,
    "1B": evidence_1B,
    "2A": evidence_2A,
    "2B": evidence_2B,
    3: evidence_3,
    4: evidence_4,
  };
  const rows = data?.map((element, index) => (
    <tr key={index}>
      <td>
        <Text
          variant="link"
          component={Link}
          to={{ pathname: `${url}/${element.name}`, state: { ...element } }}
        >
          <ReactMarkdown>{element.display}</ReactMarkdown>
        </Text>
      </td>
      <td>
        <Text>{element.phenotype}</Text>
      </td>
      <td>
        <Group>
          <Image
            src={
              element.evidence === "0" || element.evidence === 0
                ? evidence_NA
                : evidenceIcons[element.evidence]
            }
            width={30}
          />
          <Image
            src={element.guidelines ? guidelines : guidelines_NA}
            width={30}
          />
          <Image src={element.druglabel ? labels : labels_NA} width={30} />
        </Group>
      </td>
    </tr>
  ));
  return (
    <Table
      verticalSpacing="md"
      striped
      highlightOnHover
      // horizontalSpacing="xl"
      fontSize="lg"
    >
      <thead>
        <tr>
          <th>{localStrings.genesTable.gene}</th>
          <th>{localStrings.genesTable.phenotype}</th>
          <th>{localStrings.genesTable.evidence}</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
};

export default GenesTable;
