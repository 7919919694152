import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { CookieConsentsProvider } from "@enzsft/react-cookie-consents";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";

import { store } from "./redux/store";
import App from "./App";

// import "./styles/main.scss";
import theme from "./styles/theme";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

var instance = null;

if (process.env.REACT_APP_ENV === "prod") {
  instance = createInstance({
    urlBase: `${process.env.REACT_APP_FRONTEND_HOST}/webapp`,
    siteId: process.env.REACT_APP_MATOMO_SITE_ID,
    trackerUrl: `${process.env.REACT_APP_MATOMO_HOST}/matomo.php`,
    srcUrl: `${process.env.REACT_APP_MATOMO_HOST}/matomo.js`,
    linkTracking: true,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <MatomoProvider value={instance}>
      <CookieConsentsProvider cookieName="cookieConsents" expiryInDays={365}>
        <Provider store={store}>
          <MantineProvider withNormalizeCSS withGlobalStyles theme={theme}>
            <Router>
              <Switch>
                <Route path="/:hash" component={App} />
              </Switch>
            </Router>
          </MantineProvider>
        </Provider>
      </CookieConsentsProvider>
    </MatomoProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();
