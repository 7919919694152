import React, { useEffect, useState } from "react";
import {
  Button,
  Center,
  Checkbox,
  Modal,
  Switch,
  Text,
  Title,
} from "@mantine/core";
import { useCookieConsents } from "@enzsft/react-cookie-consents";

import strings from "../assets/json/staticPages.json";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const ConsentModal = () => {
  const { lang } = useSelector((state) => state.userData);

  const location = useLocation();
  const cookieConsents = useCookieConsents();

  const hasCookie = cookieConsents.get().length > 0;

  const [opened, setOpened] = useState(!hasCookie);
  // const [dataCollection, setDataCollection] = useState(false);
  const [consent, setConsent] = useState(false);
  const [analytics, setAnalytics] = useState(true);

  useEffect(() => {
    if (location.pathname.includes("cookies")) {
      setOpened(false);
    } else {
      setOpened(!hasCookie);
    }
  }, [location, hasCookie]);

  const localStrings = strings[lang].consent;

  const handleSubmit = () => {
    if (analytics) cookieConsents.add("analytics");
    else cookieConsents.add("essential");
    setOpened(false);
  };

  return (
    <>
      <Modal
        centered
        size={600}
        withCloseButton={false}
        closeOnEscape={false}
        closeOnClickOutside={false}
        opened={opened}
        onClose={() => setOpened(false)}
      >
        <Title order={3} align="center" mb="xl">
          {localStrings.title}
        </Title>

        <Text align="center" mb="md">
          {localStrings.intro}
        </Text>

        <Switch
          label={localStrings.essential}
          color="green"
          disabled
          mb="xs"
          checked={true}
        />
        <Switch
          label={localStrings.analytics}
          color="green"
          checked={analytics}
          onChange={(e) => setAnalytics(e.target.checked)}
        />

        {/* <Checkbox
          my="xl"
          label={localStrings.dataCollection}
          color="green"
          size="sm"
          checked={dataCollection}
          onChange={(e) => setDataCollection(e.currentTarget.checked)}
        /> */}

        <Checkbox
          my="xl"
          label={localStrings.consent}
          color="green"
          size="sm"
          checked={consent}
          onChange={(e) => setConsent(e.currentTarget.checked)}
        />

        <div dangerouslySetInnerHTML={{ __html: localStrings.link }} />

        <Center>
          <Button
            sx={{ margin: "auto" }}
            onClick={handleSubmit}
            disabled={!consent}
          >
            {localStrings.button}
          </Button>
        </Center>
      </Modal>
    </>
  );
};

export default ConsentModal;
