import React from "react";
import { useSelector } from "react-redux";

import { Group, Stack, Text, ThemeIcon, Title } from "@mantine/core";

import { Category, DrugGroup, SingleDrug } from "../components";

import { RiErrorWarningFill } from "react-icons/ri";
import { FaStar } from "react-icons/fa";

import appStrings from "../assets/json/appStrings.json";
import drugStrings from "../assets/json/drugStrings.json";

const Subject = ({ match }) => {
  const category = match.params.subject;

  const { data } = useSelector((state) => state.reportData);
  const { lang } = useSelector((state) => state.userData);

  const localStrings = appStrings[lang];

  return (
    <Category>
      <Title order={2} my="lg">
        {localStrings.categories[category]}
      </Title>
      <Stack spacing="xl">
        {Object.keys(localStrings.resultBoxes).map(
          (color, index) =>
            data?.drug_index?.[category]?.[color].length > 0 && (
              <DrugGroup
                key={index}
                title={localStrings.resultBoxes[color]}
                color={color}
              >
                {data?.drug_index?.[category][color].map((drug, index) => (
                  <SingleDrug
                    key={index}
                    name={drugStrings[drug]?.[lang] || drug}
                    code={drug}
                    color={color}
                  />
                ))}
              </DrugGroup>
            )
        )}
      </Stack>
      <Group mt="xl">
        <ThemeIcon variant="outline" color="dark">
          <RiErrorWarningFill />
        </ThemeIcon>
        <Text>{localStrings.actions.sideEffects}</Text>
      </Group>
      <Group mb="xl" mt="xs">
        <ThemeIcon variant="outline" color="dark">
          <FaStar />
        </ThemeIcon>
        <Text>{localStrings.actions.favorite}</Text>
      </Group>
      <Text size="sm">
        <strong>{localStrings.warnings.general[0]}</strong>
        {localStrings.warnings.general[1]}
      </Text>
    </Category>
  );
};

export default Subject;
