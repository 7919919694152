import { configureStore } from "@reduxjs/toolkit";
import reportDataReducer from "./features/reportData";
import userDataReducer from "./features/userData";

export const store = configureStore({
  reducer: {
    reportData: reportDataReducer,
    userData: userDataReducer,
  },
});
