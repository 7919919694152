import React from "react";
import { Paper, Text } from "@mantine/core";

const DrugGroup = ({ color, title, children }) => {
  return (
    <div>
      <Text weight="bold" color={color}>
        {title}
      </Text>
      <Paper
        sx={(theme) => ({
          backgroundColor: theme.colors[color][2],
          padding: theme.spacing.lg,
          display: "flex",
          gap: theme.spacing.sm,
          flexWrap: "wrap",
        })}
      >
        {children}
      </Paper>
    </div>
  );
};

export default DrugGroup;
