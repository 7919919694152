import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReportData } from "../redux/features/reportData";

export const useGetData = () => {
  const dispatch = useDispatch();

  const { data, isLoading } = useSelector((state) => state.reportData);
  const { hash, hashIsValid, lang } = useSelector((state) => state.userData);

  useEffect(() => {
    if (hash && Object.keys(data).length === 0) {
      dispatch(getReportData({ hash, lang }));
    }
  }, [data, dispatch, hash, lang]);

  return [isLoading, hashIsValid];
};
