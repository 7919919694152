import {
  Breadcrumbs,
  Button,
  Center,
  Container,
  Group,
  Image,
  MediaQuery,
  Paper,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import React from "react";
import { FaInfo } from "react-icons/fa";
import { IoArrowBack } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import ReactMarkdown from "react-markdown";

import {
  gene_result_pt_3levels_1,
  gene_result_pt_3levels_2,
  gene_result_pt_3levels_3,
  gene_result_pt_4levels_1,
  gene_result_pt_4levels_2,
  gene_result_pt_4levels_3,
  gene_result_pt_4levels_4,
  gene_result_pt_5levels_1,
  gene_result_pt_5levels_2,
  gene_result_pt_5levels_3,
  gene_result_pt_5levels_4,
  gene_result_pt_5levels_5,
  gene_result_en_3levels_1,
  gene_result_en_3levels_2,
  gene_result_en_3levels_3,
  gene_result_en_4levels_1,
  gene_result_en_4levels_2,
  gene_result_en_4levels_3,
  gene_result_en_4levels_4,
  gene_result_en_5levels_1,
  gene_result_en_5levels_2,
  gene_result_en_5levels_3,
  gene_result_en_5levels_4,
  gene_result_en_5levels_5,
} from "../assets/img";

import appStrings from "../assets/json/appStrings.json";
import drugStrings from "../assets/json/drugStrings.json";
import { useSelector } from "react-redux";

const GenePage = ({ location, match }) => {
  const { lang } = useSelector((state) => state.userData);

  const localStrings = appStrings[lang];

  const history = useHistory();

  const {
    name,
    detailed_description,
    gene_recommendation,
    consequence,
    metabolism_level,
    color,
    phenotype,
    warning,
  } = location.state;
  const { subject, drug } = match.params;

  const breadcrumbs = [
    { title: localStrings.categories[subject] },
    { title: drugStrings?.[drug]?.[lang] || drug },
    { title: name },
  ].map((item, index) => <Text key={index}>{item.title}</Text>);

  let geneResults, label;

  if (name === "CYP2C19") {
    geneResults = {
      1: {
        text: localStrings.genesPage.results.VR,
        icon:
          lang === "en_GB"
            ? gene_result_en_5levels_1
            : gene_result_pt_5levels_1,
      },
      2: {
        text: localStrings.genesPage.results.RED,
        icon:
          lang === "en_GB"
            ? gene_result_en_5levels_2
            : gene_result_pt_5levels_2,
      },
      3: {
        text: localStrings.genesPage.results.N,
        icon:
          lang === "en_GB"
            ? gene_result_en_5levels_3
            : gene_result_pt_5levels_3,
      },
      4: {
        text: localStrings.genesPage.results.RAP,
        icon:
          lang === "en_GB"
            ? gene_result_en_5levels_4
            : gene_result_pt_5levels_4,
      },
      5: {
        text: localStrings.genesPage.results.UR,
        icon:
          lang === "en_GB"
            ? gene_result_en_5levels_5
            : gene_result_pt_5levels_5,
      },
    };
    label = localStrings.geneResultLabel["5levels"];
  } else if (name === 'UGT2B15') {
    geneResults = {
      1: {
        text: localStrings.genesPage.results.VR,
        icon:
          lang === "en_GB"
            ? gene_result_en_3levels_1
            : gene_result_pt_3levels_1,
      },
      2: {
        text: localStrings.genesPage.results.RED,
        icon:
          lang === "en_GB"
            ? gene_result_en_3levels_2
            : gene_result_pt_3levels_2,
      },
      3: {
        text: localStrings.genesPage.results.N,
        icon:
          lang === "en_GB"
            ? gene_result_en_3levels_3
            : gene_result_pt_3levels_3,
      }
    };
    label = localStrings.geneResultLabel["3levels"];
  } else {
    geneResults = {
      1: {
        text: localStrings.genesPage.results.VR,
        icon:
          lang === "en_GB"
            ? gene_result_en_4levels_1
            : gene_result_pt_4levels_1,
      },
      2: {
        text: localStrings.genesPage.results.RED,
        icon:
          lang === "en_GB"
            ? gene_result_en_4levels_2
            : gene_result_pt_4levels_2,
      },
      3: {
        text: localStrings.genesPage.results.N,
        icon:
          lang === "en_GB"
            ? gene_result_en_4levels_3
            : gene_result_pt_4levels_3,
      },
      4: {
        text: localStrings.genesPage.results.UR,
        icon:
          lang === "en_GB"
            ? gene_result_en_4levels_4
            : gene_result_pt_4levels_4,
      },
    };
    label = localStrings.geneResultLabel["4levels"];
  }

  return (
    <Container>
      <Title order={2} mt="xl">
        {name}
      </Title>
      <Group my="xl">
        <Button
          size="sm"
          variant="outline"
          color="gray"
          onClick={() => history.goBack()}
        >
          <IoArrowBack />
        </Button>
        <Breadcrumbs>{breadcrumbs}</Breadcrumbs>
      </Group>
      {detailed_description && (
        <Text>
          <ReactMarkdown>{detailed_description}</ReactMarkdown>
        </Text>
      )}
      {metabolism_level ? (
        <>
          <Title order={3} mt="xl" mb="xl" align="center">
            {localStrings.genesPage.resultsTitle}
          </Title>
          <MediaQuery smallerThan="md" styles={{ width: "100%" }}>
            <Center sx={{ width: "50%", marginInline: "auto" }}>
              <Image src={geneResults[metabolism_level].icon} />
            </Center>
          </MediaQuery>
          <Text weight="bold" size="lg" align="center" mt="sm">
            {geneResults[metabolism_level].text}
          </Text>
          <Text align="center" size="sm" mt="sm" color="gray">
            {label}
          </Text>
        </>
      ) : (
        <>
          <Paper
            p="md"
            sx={(theme) => ({ backgroundColor: theme.colors[color][1] })}
          >
            <Text weight="bold" align="center">
              {phenotype}
            </Text>
          </Paper>
          {warning && (
            <Text size="sm" mt="sm">
              § {localStrings.geneWarning}
            </Text>
          )}
        </>
      )}
      {consequence && (
        <Stack my="md">
          {consequence.map((t, i) => (
            <Text key={i}>
              <ReactMarkdown>{t}</ReactMarkdown>
            </Text>
          ))}
        </Stack>
      )}
      {gene_recommendation && (
        <Paper
          p="lg"
          sx={(theme) => ({ backgroundColor: theme.colors[color][1] })}
        >
          <Text weight="bold" mb="xs">
            {localStrings.genesPage.recommendations}
          </Text>
          {gene_recommendation.map((t, i) => (
            <ReactMarkdown key={i} children={t} />
          ))}
        </Paper>
      )}
      <Group my="xl" noWrap>
        <ThemeIcon variant="outline">
          <FaInfo />
        </ThemeIcon>
        <Text size="sm">{localStrings.warnings.gene}</Text>
      </Group>
    </Container>
  );
};

export default GenePage;
