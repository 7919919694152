import React from "react";
import { useSelector } from "react-redux";

import { Anchor, Container, Divider, List, Text, Title } from "@mantine/core";

import strings from "../../assets/json/staticPages.json";

const References = () => {
  const { lang } = useSelector((state) => state.userData);

  const localStrings = strings[lang].refs;

  return (
    <Container>
      <Title my="xl" order={2}>
        {localStrings.title}
      </Title>
      <Text mb="xs" size="lg" weight="bold">
        {localStrings.content.websitesTitle}
      </Text>
      <List type="ordered" spacing="lg">
        {localStrings.content.websites.map((t, i) => (
          <List.Item key={i}>
            {t.text} <Anchor>{t.link}</Anchor> {t.text2 && <>({t.text2} <Anchor>{t.link2}</Anchor>)</>}
          </List.Item>
        ))}
      </List>
      <Divider my="xl" />
      <Text mb="xs" size="lg" weight="bold">
        {localStrings.content.referencesTitle}
      </Text>
      <List spacing="lg">
        {localStrings.content.references.map((t, i) => (
          <List.Item key={i}>{t}</List.Item>
        ))}
      </List>
      {/* <Text mt="sm" size="xs" color="gray">
        {localStrings.content.disclaimer}
      </Text> */}
    </Container>
  );
};

export default References;
