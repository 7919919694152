import React, { useState, useEffect } from "react";
import {
  Button,
  Center,
  Group,
  Modal,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { IoShareOutline } from "react-icons/io5";
import { MdOutlineAddBox } from "react-icons/md";
import { FaSafari } from "react-icons/fa";
import { useSelector } from "react-redux";

import strings from "../assets/json/appStrings.json";

const InstallModal = () => {
  const [opened, setOpened] = useState(false);
  const [event, setEvent] = useState(null);

  const { lang } = useSelector((state) => state.userData);

  const localStrings = strings[lang].installModal;

  // Detects if device is on iOS
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  // Detects if device is is Safari
  const isInSafari = () => {
    let userAgent = window.navigator.userAgent;

    // Detect Chrome
    let chromeAgent =
      userAgent.indexOf("Chrome") > -1 || userAgent.indexOf("CriOS") > -1;

    // Detect Safari
    let safariAgent = userAgent.indexOf("Safari") > -1;

    // Detect Firefox
    let firefoxAgent = userAgent.indexOf("FxiOS") > -1;

    // Safari matches Chrome and firefox
    if ((chromeAgent && safariAgent) || (firefoxAgent && safariAgent))
      return false;
    return safariAgent;
  };

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      setEvent(e);
      setOpened(true);
      console.log(`'beforeinstallprompt' event was fired.`);
    });
  }, []);

  const installApp = async (e) => {
    e.preventDefault();
    setOpened(false);
    console.log(event);
    event.prompt();
    const { outcome } = await event.userChoice;
    console.log(`User response to the install prompt: ${outcome}`);
    setEvent(null);
  };

  return (
    <Modal
      centered
      opened={opened}
      withCloseButton={false}
      onClose={() => setOpened(false)}
    >
      <Text align="center" mb="lg" weight="bold" size="lg">
        {localStrings.title}
      </Text>
      {isIos() && isInSafari() ? (
        <Stack>
          <Group noWrap>
            <ThemeIcon variant="light" size="xl">
              <FaSafari size={25} />
            </ThemeIcon>
            <Text>{localStrings.safari}</Text>
          </Group>
          <Group noWrap>
            <ThemeIcon variant="light" size="xl">
              <IoShareOutline size={25} />
            </ThemeIcon>
            <Text>{localStrings.share}</Text>
          </Group>
          <Group noWrap>
            <ThemeIcon variant="light" size="xl">
              <MdOutlineAddBox size={25} />
            </ThemeIcon>
            <Text>{localStrings.screen}</Text>
          </Group>
          <Button variant="light" onClick={() => setOpened(false)}>
            {localStrings.later}
          </Button>
        </Stack>
      ) : (
        <Center>
          <Group>
            <Button onClick={installApp}>{localStrings.install}</Button>
            <Button variant="light" onClick={() => setOpened(false)}>
              {localStrings.later}
            </Button>
          </Group>
        </Center>
      )}
    </Modal>
  );
};

export default InstallModal;
