import axios from "axios";

const apiURL = process.env.REACT_APP_BACKEND_HOST;

export const getReportDataAPI = async ({ hash, lang }) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${apiURL}/backend/${hash}/report-data`,
      params: {
        lang,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
