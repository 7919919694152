import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";

import {
  Breadcrumbs,
  Button,
  Chip,
  Container,
  Group,
  Paper,
  Space,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core";

import { GenesTable, ResultCards } from "../components";

import { IoArrowBack } from "react-icons/io5";
import { FaInfo } from "react-icons/fa";
import {
  addFavoriteDrug,
  addSideEffectsDrug,
  removeFavoriteDrug,
  removeSideEffectsDrug,
} from "../redux/features/userData";

import appStrings from "../assets/json/appStrings.json";
import { useDrugInfo } from "../hooks/useDrugInfo";

const DrugPage = ({ match, location }) => {
  const { name, code, color } = location.state;
  const { subject } = match.params;

  const history = useHistory();
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.reportData);
  const { hash, lang } = useSelector((state) => state.userData);

  const [isFavorite, hasSideEffect] = useDrugInfo(code);

  const localStrings = appStrings[lang];

  const breadcrumbs = [
    { title: localStrings.categories[subject] },
    { title: name },
  ].map((item, index) => <Text key={index}>{item.title}</Text>);

  const currentDrug = data?.[subject]?.[code];

  const genesData = currentDrug && [
    ...currentDrug?.genes?.map((gene) => {
      return {
        name: gene.gene,
        display: gene.gene_display,
        phenotype: gene.phenotype,
        evidence: gene.evidence,
        guidelines: gene.guidelines,
        druglabel: gene.druglabel,
        detailed_description: gene.detailed_description,
        metabolism_level: gene.metabolism_level,
        consequence: gene.consequence,
        gene_recommendation: gene.gene_recommendation,
        color: color,
        warning: gene.warning,
      };
    }),
  ];

  const handleFavorites = () => {
    isFavorite
      ? dispatch(removeFavoriteDrug({ hash, code }))
      : dispatch(addFavoriteDrug({ hash, code }));
  };

  const handleSideEffects = () => {
    hasSideEffect
      ? dispatch(removeSideEffectsDrug({ hash, code }))
      : dispatch(addSideEffectsDrug({ hash, code }));
  };

  return (
    <>
      <Container px={0}>
        <Stack align="center" justify="center">
          <Paper
            sx={(theme) => ({
              backgroundColor: theme.colors[color][2],
              width: "30%",
              minWidth: "250px",
              border: `1px solid ${theme.colors[color][5]}`,
            })}
            py="xs"
          >
            <Text size="lg" weight="bold" align="center">
              {name}
            </Text>
          </Paper>
          <Group position="center">
            <Chip size="md" checked={isFavorite} onChange={handleFavorites}>
              {localStrings.actions.favorite}
            </Chip>
            <Chip
              size="md"
              checked={hasSideEffect}
              onChange={handleSideEffects}
            >
              {localStrings.actions.sideEffects}
            </Chip>
          </Group>
        </Stack>
        <Group my="xl">
          <Button
            size="sm"
            variant="outline"
            color="gray"
            onClick={() => history.goBack()}
          >
            <IoArrowBack />
          </Button>
          <Breadcrumbs>{breadcrumbs}</Breadcrumbs>
        </Group>
        <ResultCards
          altered_metabolism={currentDrug?.altered_metabolism}
          has_low_efficacy={currentDrug?.has_low_efficacy}
          has_adverse={currentDrug?.has_adverse}
        />
        <Space h="xl" />
        <Paper
          mt="xl"
          p="lg"
          radius="md"
          sx={(theme) => ({ backgroundColor: theme.colors[color][1] })}
        >
          {currentDrug?.drug_recommendation ? (
            currentDrug?.drug_recommendation?.map((t, i) => (
              <Text key={i}>
                <ReactMarkdown>{t}</ReactMarkdown>
              </Text>
            ))
          ) : (
            <Text>{localStrings.noRecommendations}</Text>
          )}
        </Paper>

        {currentDrug?.genes.length > 0 && (
          <>
            <Space h="xl" />
            <GenesTable data={genesData} />
            {currentDrug.warning && (
              <Text size="sm">§ {localStrings.geneWarning}</Text>
            )}
            <Group my="xl" noWrap>
              <ThemeIcon variant="outline">
                <FaInfo />
              </ThemeIcon>
              <Text>{localStrings.warnings.clickGene}</Text>
            </Group>
          </>
        )}
        <Text size="sm">
          <strong>{localStrings.warnings.general[0]}</strong>
          {localStrings.warnings.general[1]}
        </Text>
      </Container>
    </>
  );
};

export default DrugPage;
