import React from "react";
import {
  Group,
  Image,
  MediaQuery,
  Paper,
  SimpleGrid,
  Text,
} from "@mantine/core";
import { risk_effects, risk_efficacy, risk_metabolism } from "../assets/img";

import appStrings from "../assets/json/appStrings.json";
import { useSelector } from "react-redux";

const ResultCards = ({ altered_metabolism, has_low_efficacy, has_adverse }) => {
  const { lang } = useSelector((state) => state.userData);

  const localStrings = appStrings[lang];

  const cards = [
    {
      icon: <Image src={risk_metabolism} width={50} />,
      label: localStrings.resultCards.altered_metabolism,
      active: altered_metabolism,
    },
    {
      icon: <Image src={risk_efficacy} width={50} />,
      label: localStrings.resultCards.has_low_efficacy,
      active: has_low_efficacy,
    },
    {
      icon: <Image src={risk_effects} width={50} />,
      label: localStrings.resultCards.has_adverse,
      active: has_adverse,
    },
  ];
  return (
    <SimpleGrid
      position="center"
      cols={3}
      breakpoints={[{ maxWidth: "md", cols: 1 }]}
    >
      {cards.map((card, index) => (
        <Paper key={index} p="xl" radius="lg" shadow="md">
          <MediaQuery largerThan="md" styles={{ flexDirection: "column" }}>
            <Group noWrap sx={{ opacity: !card.active && "0.15" }}>
              {card.icon}
              <Text align="center">{card.label}</Text>
            </Group>
          </MediaQuery>
        </Paper>
      ))}
    </SimpleGrid>
  );
};

export default ResultCards;
