import React from "react";
import { useSelector } from "react-redux";
import { Container, Title } from "@mantine/core";

import strings from "../../assets/json/staticPages.json";

const Terms = () => {
  const { lang } = useSelector((state) => state.userData);

  const localStrings = strings[lang].terms;

  return (
    <Container>
      <Title my="xl" order={2}>
        {localStrings.title}
      </Title>
      <div dangerouslySetInnerHTML={{ __html: localStrings.content }} />
    </Container>
  );
};

export default Terms;
