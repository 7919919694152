import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

import { Button } from "@mantine/core";

import { FaStar } from "react-icons/fa";
import { RiErrorWarningFill } from "react-icons/ri";

import { useDrugInfo } from "../hooks/useDrugInfo";

const SingleDrug = ({ name, code, color, isFavoritePage, category }) => {
  const { url } = useRouteMatch();

  const [isFavorite, hasSideEffect] = useDrugInfo(code);

  return (
    <>
      <Button
        variant={isFavoritePage ? "filled" : "outline"}
        color={isFavoritePage ? color : "dark"}
        component={Link}
        to={{
          pathname: isFavoritePage
            ? `category/${category}/${code}`
            : `${url}/${code}`,
          state: { name, code, color },
        }}
        leftIcon={isFavorite && <FaStar />}
        rightIcon={hasSideEffect && <RiErrorWarningFill />}
      >
        {name}
      </Button>
    </>
  );
};

export default SingleDrug;
