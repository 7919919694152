import React, { useState } from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { updateLang } from "../redux/features/userData";

import {
  AppShell,
  Navbar,
  Header,
  Aside,
  Text,
  MediaQuery,
  Burger,
  useMantineTheme,
  Stack,
  Group,
  ThemeIcon,
  UnstyledButton,
  Center,
  Drawer,
  Divider,
  Select,
  Image,
} from "@mantine/core";

import { AiFillStar } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { hgLogo, nimgeneticsLogo, unilabsLogo, PsychiatryIcon, NeurologyIcon } from "../assets/img";

import appStrings from "../assets/json/appStrings.json";

import config from "../config/business.json";

const NavLink = ({ icon, text, path, setOpened, centered }) => {
  const location = useLocation();
  const { url } = useRouteMatch();
  let active = location.pathname.includes(path);
  return (
    <UnstyledButton
      component={Link}
      to={`${url}/${path}`}
      sx={(theme) => ({
        display: "block",
        width: "100%",
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color: theme.black,
        backgroundColor: active && theme.colors.gray[2],

        "&:hover": {
          backgroundColor: theme.colors.gray[1],
        },
      })}
      onClick={() => setOpened(false)}
    >
      <Group noWrap position={centered && "center"}>
        {icon && (
          <ThemeIcon variant="light" size="lg">
            {icon}
          </ThemeIcon>
        )}

        <Text size="md" align={centered && "center"}>
          {text}
        </Text>
      </Group>
    </UnstyledButton>
  );
};

const AppWrapper = ({ children }) => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();

  let thisLogo;
  switch (theme.other.logo) {
  case "hg":
    thisLogo = hgLogo;
    break;
  case "unilabs":
    thisLogo = unilabsLogo;
    break;
	case "nimgenetics":
    thisLogo = nimgeneticsLogo;
    break;

  default:
    thisLogo = hgLogo;
    break;
}


  const [opened, setOpened] = useState(false);

  const { lang } = useSelector((state) => state.userData);

  const languages = [
    ...Object.keys(config.languages).map((lang) => ({
      value: lang,
      label: config.languages[lang].text,
    })),
  ];

  const navigationStrings = appStrings[lang].navigation;
  const navlinks = [
    {
      icon: <AiFillStar size={20} />,
      text: navigationStrings.favorites,
      path: "favorites",
    },
    {
      icon: <PsychiatryIcon width={20} fill={theme.colors.green[6]} />,
      text: navigationStrings.psychiatry,
      path: "category/psychiatry",
    },
    {
      icon: <NeurologyIcon width={20} fill={theme.colors.green[6]} />,
      text: navigationStrings.neurology,
      path: "category/neurology",
    },
    {
      icon: <BiSearch size={20} />,
      text: navigationStrings.search,
      path: "search",
    },
  ];

  const pageLinks = [
    { text: navigationStrings.help, path: "help" },
    { text: navigationStrings.cookies, path: "cookies" },
    { text: navigationStrings.terms, path: "terms" },
    { text: navigationStrings.references, path: "references" },
  ];

  const setLang = (value) => {
    dispatch(updateLang(value));
    setOpened(false);
  };

  return (
    <AppShell
      styles={{
        main: {
          background: theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="lg"
      fixed
      padding="xl"
      navbar={
        <Navbar
          p="xl"
          hiddenBreakpoint="sm"
          hidden={true}
          width={{ sm: 300 }}
          sx={{ zIndex: 10 }}
        >
          <Stack mt="xl">
            {navlinks.map((link, index) => (
              <NavLink key={index} {...link} setOpened={setOpened} />
            ))}
          </Stack>
        </Navbar>
      }
      aside={
        <MediaQuery smallerThan="lg" styles={{ display: "none" }}>
          <Aside
            p="md"
            hiddenBreakpoint="lg"
            width={{ sm: 300 }}
            sx={{ zIndex: 10 }}
          ></Aside>
        </MediaQuery>
      }
      header={
        <Header
          height={70}
          p="md"
          sx={(theme) => ({
            backgroundColor: theme.other.headerColor,
            boxShadow: theme.shadows.sm,
          })}
          zIndex={200}
          fixed
        >
          <Center sx={{ height: "100%", width: "100%" }}>
            <MediaQuery largerThan="lg" styles={{ width: "60%" }}>
              <MediaQuery largerThan="sm" styles={{ width: "70%" }}>
                <Group position="apart" sx={{ width: "90%" }}>
                  {/* <Logo width={200} fill="white" /> */}
                  <Image src={thisLogo} width={200} />
                  <Burger
                    opened={opened}
                    onClick={() => setOpened((o) => !o)}
                    size="sm"
                    color={theme.colors.gray[0]}
                  />
                </Group>
              </MediaQuery>
            </MediaQuery>
          </Center>
        </Header>
      }
    >
      {children}
      <Drawer
        opened={opened}
        onClose={() => setOpened(false)}
        padding="lg"
        position="right"
      >
        <Select
          placeholder="Language"
          data={languages}
          value={lang}
          onChange={setLang}
        />
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <Stack mt="xl">
            {navlinks.map((link, index) => (
              <NavLink key={index} {...link} setOpened={setOpened} />
            ))}
          </Stack>
        </MediaQuery>
        <Divider my="xl" />
        <Stack>
          {pageLinks.map((link, index) => (
            <NavLink key={index} {...link} setOpened={setOpened} centered />
          ))}
        </Stack>
      </Drawer>
    </AppShell>
  );
};
export default AppWrapper;
