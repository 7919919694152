import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookieConsents } from "@enzsft/react-cookie-consents";

import { Redirect, Route, useLocation } from "react-router-dom";
import {
  Cookies,
  DrugPage,
  Favorites,
  GenePage,
  Help,
  References,
  Search,
  Subject,
  Terms,
} from "./pages";
import {
  updateCompany,
  updateHash,
  updateLang,
} from "./redux/features/userData";
import AppWrapper from "./wrapper/AppWrapper";

import config from "./config/business.json";
import { InstallModal, ConsentModal } from "./components";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const App = ({ match }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const cookieConsents = useCookieConsents();
  const consent = cookieConsents.get();
  const { trackPageView } = useMatomo();

  const urlhash = match.params.hash;
  const { hash } = useSelector((state) => state.userData);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "prod" && consent[0] === "analytics") {
      trackPageView();
    }
  }, [trackPageView, consent]);

  useEffect(() => {
    if (!hash) {
      dispatch(updateHash(urlhash));
    }
  }, [hash, dispatch, urlhash]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.forEach(function (value, key) {
      console.log(value, key);
      if (key === "lang" && Object.keys(config["languages"]).includes(value)) {
        dispatch(updateLang(value));
      }
      if (key === "id" && Object.values(config["companies"]).includes(value)) {
        dispatch(
          updateCompany(
            Object.keys(config["companies"]).find(
              (i) => config["companies"][i] === value
            )
          )
        );
      }
    });
  }, [dispatch, location.search]);

  return (
    <AppWrapper>
      <Route
        exact
        path={`${match.path}/category/:subject`}
        component={Subject}
      />
      <Route
        exact
        path={`${match.path}/category/:subject/:drug`}
        component={DrugPage}
      />
      <Route
        exact
        path={`${match.path}/category/:subject/:drug/:gene`}
        component={GenePage}
      />
      <Route exact path={`${match.path}/favorites`} component={Favorites} />
      <Route exact path={`${match.path}/search`} component={Search} />

      <Route exact path={`${match.path}/help`} component={Help} />
      <Route exact path={`${match.path}/cookies`} component={Cookies} />
      <Route exact path={`${match.path}/terms`} component={Terms} />
      <Route exact path={`${match.path}/references`} component={References} />

      {!location.pathname.includes("cookies") && (
        <Redirect to={`${match.url}/favorites`} />
      )}
      <InstallModal />
      <ConsentModal />
    </AppWrapper>
  );
};
export default App;
