import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addFavoriteDrugAPI,
  addSideEffectsDrugAPI,
  removeFavoriteDrugAPI,
  removeSideEffectsDrugAPI,
} from "../../api/userAPI";

import config from "../../config/business.json";

const lang = localStorage.getItem("lang");
const company = localStorage.getItem("company");

const initialState = {
  hash: null,
  hashIsValid: false,
  lang: lang ? lang : Object.keys(config.languages)[0],
  company: company ? company : Object.keys(config.companies)[0],
  favoriteDrugs: [],
  sideEffectsDrugs: [],
};

export const addFavoriteDrug = createAsyncThunk(
  "userData/addFavoriteDrug",
  async (data, thunkAPI) => {
    try {
      const response = await addFavoriteDrugAPI(data);
      return response.payload;
    } catch (error) {
      thunkAPI.rejectWithValue();
    }
  }
);

export const removeFavoriteDrug = createAsyncThunk(
  "userData/removeFavoriteDrug",
  async (data, thunkAPI) => {
    try {
      const response = await removeFavoriteDrugAPI(data);
      return response.payload;
    } catch (error) {
      thunkAPI.rejectWithValue();
    }
  }
);

export const addSideEffectsDrug = createAsyncThunk(
  "userData/addSideEffectsDrug",
  async (data, thunkAPI) => {
    try {
      const response = await addSideEffectsDrugAPI(data);
      return response.payload;
    } catch (error) {
      thunkAPI.rejectWithValue();
    }
  }
);

export const removeSideEffectsDrug = createAsyncThunk(
  "userData/removeSideEffectsDrug",
  async (data, thunkAPI) => {
    try {
      const response = await removeSideEffectsDrugAPI(data);
      return response.payload;
    } catch (error) {
      thunkAPI.rejectWithValue();
    }
  }
);

const data = createSlice({
  name: "userData",
  initialState,
  reducers: {
    updateHash: (state, action) => {
      state.hash = action.payload;
    },
    validateHash: (state, action) => {
      state.hashIsValid = action.payload;
    },
    updateLang: (state, action) => {
      localStorage.setItem("lang", action.payload);
      state.lang = action.payload;
    },
    updateCompany: (state, action) => {
      localStorage.setItem("company", action.payload);
      window.location.reload();
      state.company = action.payload;
    },
    setFavoriteDrugs: (state, action) => {
      state.favoriteDrugs = action.payload;
    },
    setsideEffectsDrugs: (state, action) => {
      state.sideEffectsDrugs = action.payload;
    },
  },
  extraReducers: {
    [addFavoriteDrug.fulfilled]: (state, action) => {
      state.favoriteDrugs = action.payload.favoriteDrugs;
    },
    [removeFavoriteDrug.fulfilled]: (state, action) => {
      state.favoriteDrugs = action.payload.favoriteDrugs;
    },
    [addSideEffectsDrug.fulfilled]: (state, action) => {
      state.sideEffectsDrugs = action.payload.sideEffectsDrugs;
    },
    [removeSideEffectsDrug.fulfilled]: (state, action) => {
      state.sideEffectsDrugs = action.payload.sideEffectsDrugs;
    },
  },
});

export const {
  updateHash,
  validateHash,
  updateLang,
  updateCompany,
  setFavoriteDrugs,
  setsideEffectsDrugs,
} = data.actions;
export default data.reducer;
